<template>
  <div class="lerning_center_warp">
    <!-- :style="{'width':discuss?'680px':'225px'}" -->

    <div class="left"
         :style="`${discuss == 0?'width:1212rem;margin-left:94px;':'width:940rem;'}`">
      <div class="topic">
        <div class="block"
             @click="toPage('/freedomTopic')"
             v-if="!showChapter">
          <span>自由选题</span>
          <span :style="discuss == 0?'font-size:24rem!important;':''">
            自主选择题目
          </span>
          <img src="@/static/center/freedom.png"
               :style="{'width':discuss == 0?'90rem':'80rem','height':discuss == 0?'90rem':'80rem'}"
               class="ziyou"
               alt="">
        </div>
        <div v-else
             class="small">
          <div @click="toPage('/freedomTopic')"
               :style="{'height':discuss == 0?'150rem':'','width':discuss == 0?'465rem':'',}">
            <span>自由选题</span>
            <img src="@/static/center/freedom.png"
                 :style="{'width':discuss == 0?'90rem':'80rem','height':discuss == 0?'90rem':'80rem'}"
                 alt="">
          </div>

          <div @click="toChapter"
               :style="{'height':discuss == 0?'150rem':'','width':discuss == 0?'465rem':'',}">
            <span>章节训练</span>
            <img src="@/static/center/zhangjie.png"
                 :style="{'width':discuss == 0?'90rem':'80rem','height':discuss == 0?'90rem':'80rem'}"
                 alt="">
          </div>

        </div>
        <div class="small">
          <div @click="toPage('/teacherPaper')">
            <!-- -->
            <span>
              教师试卷
            </span>
            <!-- <span :style="discuss == 0?'font-size:22rem!important;':''">
              模拟测试 ｜ 各地区真题
            </span> -->
            <img src="@/static/center/teacher.png"
                 :style="{'width':discuss == 0?'90rem':'80rem','height':discuss == 0?'90rem':'80rem'}"
                 alt="">
          </div>

          <div @click="toPage('/mapMind')">
            <span>
              AI导学
            </span>

            <img src="@/static/center/mindMap.png"
                 :style="{'width':discuss == 0?'90rem':'90rem','height':discuss == 0?'90rem':'90rem'}"
                 alt="">
          </div>
        </div>

      </div>
      <div class="progress">
        <div class="title">
          <span>学习进度</span><span class="more"
                @click="toPage('/learnProgress')">更多</span>
        </div>
        <div class="papers">
          <div class="paper"
               :style="{'background':bgColors[index].background,'color':bgColors[index].textcolor}"
               v-for="(item,index) in progressList"
               :key="index">
            <div class="tag">
              {{item.module_name}}
            </div>
            <div class="content">
              {{item.paper_desc}}
            </div>
            <div class="btn"
                 @click="doPaper(item)">继续做卷</div>
            <div class="time"
                 :style="{'color':bgColors[index].textcolor}">
              {{item.paper_time}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right"
         v-if="discuss">
      <div class="head">
        <span>线上答疑</span>
        <span @click="toPage('/dayi')">更多</span>
      </div>
      <div class="content_wrap">
        <div class="item"
             v-for="(item,index) in dayiList"
             @click="toDayi(item.discuss_id)"
             :key="index">
          <div class="topic_name">
            {{item.discuss_title}}
          </div>
          <div class="content">
            {{item.discuss_content}}
          </div>
          <div class="btn">
            {{item.discuss_re_times?`查看回复`:'暂无回复'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getStudyModeSubject } from '@/api/subject.js'
import { instance } from '@/utils/http.js'
import { getOnlineAnswer } from '@/api/post.js'
import { getLearnProcess } from '@/api/learnProcess.js'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { backgoundColor } from './background.js'

//let backgColors = ['#D2DCF9', '#D2F9F4', '#DED2F9', '#F9F1D2', '#9176FA']
let bgColors = backgoundColor
let progressList = ref([])
let dayiList = ref([])
let discuss = ref(1)
let router = useRouter()
let showChapter = ref(false)

const getSubject = async () => {
  const { data } = await getStudyModeSubject()
  if (data.list.length) {
    showChapter.value = true
  }
}
const getProgress = async () => {
  const { data } = await getLearnProcess()
  progressList.value = data.list

}
const getDayi = async () => {
  const { data } = await getOnlineAnswer()
  dayiList.value = data.list
}
const doPaper = (item) => {
  window.localStorage.removeItem('testpaperCardIndex')
  window.localStorage.removeItem('paper')
  window.localStorage.removeItem('images')
  router.push('/doPaper?user_paper_id=' + item.user_paper_id)
}
const toPage = (path) => {
  router.push(path)
}
const toDayi = (id) => {
  let form = {
    discuss_id: id
  }
  router.push('/dayi/info?form=' + JSON.stringify(form))
}
const toChapter = () => {
  router.push('/chapter')
}

onMounted(() => {
  getProgress()
  getDayi()
  getSubject()
  discuss.value = JSON.parse(window.localStorage.getItem('userInfo')).discuss
})
/*
 // beforeRouteLeave (to, from, next) {
 //   let path = ['/freedomTopic', '/teacherPaper', '/chapter']
 //   if (path.indexOf(to.path) != -1) {
 //     to.meta.keepAlive = false; //  不缓存，即刷新
 //   }
 //   next();
 // },
 methods: {

 }
}*/


</script>

<style lang="scss" scoped>
.lerning_center_warp {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 53px);
  padding-top: 90rem;
  // padding-left: 130rem;
  padding-left: 6.76vw;

  div {
    flex-shrink: 0;
  }
  .left {
    margin-right: 31px;
    .topic {
      margin-bottom: 50rem;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      .block {
        width: 440rem;
        height: 340rem;
        background: #fefdf9;
        box-shadow: 0px 0px 0px 0px rgba(229, 229, 229, 1);
        border-radius: 60rem;
        margin-right: 60rem;
        border: 20rem solid #fac376;
        position: relative;
        cursor: pointer;
        img {
          width: 120rem;
          height: 120rem;
          position: absolute;
          bottom: 10%;
          right: 8%;
        }
        .ziyou {
          width: 120rem !important;
          height: 120rem !important;
        }

        span {
          text-indent: 32rem;
          display: block;
          &:nth-child(1) {
            margin-top: 40rem;
            font-size: 32rem;
            font-weight: 800;
            color: #000000;
          }
          &:nth-child(2) {
            font-size: 22rem;
            font-weight: bold;
            color: #666666;
            margin-top: 10px;
          }
        }
      }

      .small {
        //  width: 440rem;
        //height: 150rem !important;
        border: none;
        background: none;
        position: relative;

        div {
          cursor: pointer;
          flex: 1;
          width: 440rem;
          height: 150rem;
          border: 12rem solid #fac376;
          box-shadow: 0px 0px 0px 0px rgba(229, 229, 229, 1);
          border-radius: 40rem;
          display: flex;
          font-size: 32rem;
          font-weight: 800;
          color: #000000;
          background: #fefdf9;
          position: relative;
          &:nth-child(2) {
            margin-top: 40rem;
          }
          span {
            margin-top: 33rem;
            text-indent: 40rem;
          }
          img {
            width: 61px;
            height: 53px;
            position: absolute;
            top: 20rem;
            right: 15rem;
          }
        }
      }
    }
    .progress {
      width: 100%;
      // width: 940rem;
      height: 410rem;
      background: rgba(5, 48, 241, 0.7);
      border-radius: 50rem;
      overflow: hidden;
      .more {
        padding: 2rem 40rem;
        font-size: 30rem;
        background: rgba(0, 0, 0, 0.1);
        font-weight: 500;
        color: #ddd;
        border-radius: 35rem;
        cursor: pointer;
      }
      .title {
        font-size: 32rem;
        font-weight: 800;
        color: #ffffff;
        margin-top: 20rem;
        margin: 20rem 40rem 0;
        display: flex;
        justify-content: space-between;
      }
      .papers {
        &::-webkit-scrollbar-thumb {
          background-color: #ffffff52;
        }
        margin-top: 20rem;
        display: flex;
        padding-left: 50rem;
        overflow: scroll;
        .paper {
          position: relative;
          // 使用flex 布局的时候，宽度被挤压，使用flex-shrink属性即可解决问题
          flex-shrink: 0;
          width: 250rem;
          height: 270rem;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
          border-radius: 15px;
          margin-right: 45rem;
          // scrollbar-width: none; /* Firefox */
          // -ms-overflow-style: none; /* IE 10+ */
          // ::-webkit-scrollbar {
          //   display: none; /* Chrome Safari */
          // }
          .tag {
            position: absolute;
            top: 10rem;
            padding: 10rem 30rem;
            left: -10rem;
            background-image: url("../../static/center/tag.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24rem;
            font-weight: bold;
            color: #ffffff;
          }
          .content {
            margin-top: 70rem;
            height: 99rem;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            font-size: 24rem;
            line-height: 33rem;
            font-weight: bold;
            padding: 0 10rem;
          }
          .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22rem;
            font-weight: bold;
            color: #000000;
            background-color: #ffffff;
            margin: 0 auto;
            width: 130rem;
            height: 44rem;
            border-radius: 40rem;
            margin-top: 9rem;
            cursor: pointer;
          }
          .time {
            font-size: 20rem;
            font-weight: 500;
            color: #0d2878;
            text-align: center;
            margin-top: 10rem;
          }
        }
      }
    }
  }
  .right {
    width: 480rem;
    height: 800rem;
    box-shadow: 0px 0px 0px 0px rgba(229, 229, 229, 1);
    background: #fefdf9;
    border-radius: 60rem;
    border: 20rem solid #fac376;
    margin-right: 130rem;
    overflow: hidden;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        margin-top: 30rem;
        &:nth-child(1) {
          text-indent: 34rem;
          font-size: 32rem;
          font-weight: 800;
          color: #000000;
        }
        &:nth-child(2) {
          color: #be784b;
          font-weight: 500;
          font-size: 24rem;
          margin-right: 26rem;
          cursor: pointer;
        }
      }
    }
    .content_wrap {
      overflow-y: scroll;
      height: 695rem;
      /* 滚动条上的滚动滑块. */
      &::-webkit-scrollbar-thumb {
        background-color: #d4af49da;
      }
      /*  滚动条轨道. */
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(196, 129, 4, 0.5);
        box-shadow: inset 0 0 6px rgba(196, 129, 4, 0.5);
      }
    }
    .item {
      position: relative;
      width: 380rem;
      height: 190rem;
      border-radius: 10rem;
      box-shadow: 0px 0px 4rem -1rem rgba(177, 177, 177, 0.5);
      background: #ffe9e0;
      margin: 0 auto;
      margin-top: 20rem;
      margin-bottom: 30rem;
      overflow: hidden;
      .topic_name {
        margin: 15rem 20rem 8rem;
        font-weight: bold;
        font-size: 24rem;
        color: #b76c3c;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .content {
        padding: 0 20rem;
        font-weight: 500;
        color: #666666;
        font-size: 20rem;
        line-height: 28rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .btn {
        position: absolute;
        bottom: 16rem;
        right: 20rem;
        background: #ff664e;
        border-radius: 40rem;
        font-size: 22rem;
        font-weight: bold;
        color: #ffffff;
        padding: 7rem 21rem;
        margin-top: 16rem;
        cursor: pointer;
      }
    }
  }
}
</style>